import React from "react";

import "./stylesNew.scss";

const BlogCard = (props) => {
  const {
    thumbnail,
    title,
    // about,
    link,
    author,
    publishDate,
    readTime,
    category,
  } = props;

  return (
    <div className="blog-footer-card-container">
      {/* <p><span>{auther}</span> - <span>{publishDate}</span> - {readTime}min</p> */}
      <div className="blog-img-div">
        <a href={"/blog/" + link} target="_blank" rel="noopener noreferrer">
          <img src={thumbnail} alt={title} />
        </a>
      </div>

      <div className="blog-author">
        {author && category && (
          <a href={"/blog/" + link} target="_blank" rel="noopener noreferrer">
            {author} in <span>{category}</span>
          </a>
        )}
      </div>
      <div className="title">
        <a href={"/blog/" + link} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </div>

      <div className="date-time">
        {publishDate && readTime && (
          <a href={"/blog/" + link} target="_blank" rel="noopener noreferrer">
            {publishDate} . {readTime} min read
          </a>
        )}
      </div>
    </div>
  );
};

export default BlogCard;
